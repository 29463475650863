<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{changeText($t('elective_pools_info'))}}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('pool_name')">
                                    <b-form-input
                                        v-model="defineForm.name"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('slot_type')">
                                    <slot-type-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.type"
                                        nonFilter="course">
                                    </slot-type-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="facultyCode" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.facultyCode">
                                    </faculty-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="departmentCode" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('department')">
                                    <department-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.departmentCode"
                                        :faculty_code="defineForm.facultyCode">
                                    </department-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="programCode" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.programCode"
                                        :faculty_code="defineForm.facultyCode"
                                        :department_code="defineForm.departmentCode">
                                    </program-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('status')">
                                    <status-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.status">
                                    </status-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <div class="mt-4">
                                <h6 class="text-uppercase mb-0">{{defineForm.name}} <span v-if="defineForm.name != null">{{$t('courses')}}</span></h6>
                            </div>
                            <hr>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                                    <ValidationProvider name="courseSearch" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('search_course')">
                                            <course-auto-complete :query="searchResultText" v-model="searchResult" :validate-error="errors[0]"></course-auto-complete>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                                    <b-button class="mt-5" variant="primary" @click="addCourse">{{$t('add')}}</b-button>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                                    <b-table striped hover :items="courseItems" :fields="fields">
                                        <template #cell(space)="data">
                                            <b-button size="sm" variant="danger" @click="deleteCourse(data)">{{$t('delete') | toUpperCase}}</b-button>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>


                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import SlotTypeSelectbox from "@/components/interactive-fields/SlotTypeSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import ElectivePoolService from "@/services/ElectivePoolService";
import ElectiveCourseService from "@/services/ElectiveCourseService";
import CourseService from "@/services/CourseService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        SlotTypeSelectbox,
        StatusSelectbox,
        CourseAutoComplete
    },

    data() {
        return {
            defineForm: {
                name: null,
                nameEn: null,
                facultyCode: null,
                departmentCode: null,
                programCode: null,
                type: null,
                status: 'a'
            },

            searchResult: null,
            searchResultText: null,

            fields: [
                {
                    key: 'space',
                    label: ''
                },
                {
                    key: 'code',
                    label: this.$t('course_code')
                },
                {
                    key: 'name',
                    label: this.$t('course_name')
                },
                {
                    key: 'language',
                    label: this.changeText(this.$t('course_language'))
                },
                {
                    key: 'theoretical_credit',
                    label: this.changeText(this.$t('theoretical_credit'))
                },
                {
                    key: 'practical_credit',
                    label:this.changeText(this.$t('practical_credit'))
                },
                {
                    key: 'credit',
                    label: this.changeText(this.$t('credit'))
                },
            ],
            courseItems: [],
            err: null
        }
    },
    metaInfo() {
        return {
            title: this.$t("prep_classes_title")
        }
    },
    methods: {

        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = {
                    name: this.defineForm.name,
                    faculty_code: this.defineForm.facultyCode,
                    department_code: this.defineForm.departmentCode,
                    program_code: this.defineForm.programCode,
                    type: this.defineForm.type,
                    status: this.defineForm.status
                }

                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('elective_pools_store_confirm_text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {

                    if(response.isConfirmed == true) {
                        ElectivePoolService.store(formData).then(response => {
                            let poolId = response.data.data.id
                            this.courseItems.forEach((value) => {
                                let courseData = {
                                    pool_id: poolId,
                                    course_id: value.id
                                }
                                ElectiveCourseService.store(courseData)
                                                     .then(() => {
                                                     })
                            })

                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$emit('hide')
                            this.defineForm = {
                                name: null,
                                nameEn: null,
                                facultyCode: null,
                                departmentCode: null,
                                programCode: null,
                                type: null,
                                status: 'a'
                            }
                            this.courseItems = []
                            this.$refs.updateForm.reset();
                        }).catch(e => {
                            if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                                if (e.data.message) {
                                    this.$refs.updateForm.errors.name.push(this.$t('api.' + e.data.message));
                                    this.$toast.error(this.$t('api.' + e.data.message));
                                }
                            }
                            else {
                                if (e.data.errors.name) {
                                    this.$refs.updateForm.errors.name.push(e.data.errors.name[0]);
                                }
                                if (e.data.errors.faculty_code) {
                                    this.$refs.updateForm.errors.facultyCode.push(e.data.errors.faculty_code[0]);
                                }
                                if (e.data.errors.department_code) {
                                    this.$refs.updateForm.errors.departmentCode.push(e.data.errors.department_code[0]);
                                }
                                if (e.data.errors.program_code) {
                                    this.$refs.updateForm.errors.programCode.push(e.data.errors.program_code[0]);
                                }
                                if (e.data.errors.type) {
                                    this.$refs.updateForm.errors.type.push(e.data.errors.type[0]);
                                }
                                if (e.data.errors.status) {
                                    this.$refs.updateForm.errors.status.push(e.data.errors.status[0]);
                                }
                            }
                        })
                    }

                })
            }
        },

        addCourse(){

            if(this.searchResult != null){
                this.courseItems.forEach((value) => {
                    if(value.id == this.searchResult){
                        this.err='hata'
                    }
                })

                if(this.err == null){
                    CourseService.get(this.searchResult).then(response => {
                        let data = response.data.data
                        this.courseItems.push({
                            id: data.id,
                            code: data.code,
                            name: data.name,
                            language: data.language,
                            theoretical_credit: data.theoretical_credit,
                            practical_credit: data.practical_credit,
                            credit: data.credit,
                        })
                    }).catch(e => {

                    })

                } else {
                    this.$swal({
                        title: this.$t('attention'),
                        text: this.$t('course_pool_err'),
                        confirmButtonText: this.$t('ok'),
                    })
                }

                this.err= null
                this.searchResult = null
                this.searchResultText = null
            }
        },

        deleteCourse(item){
            this.courseItems.forEach((value, key) => {
                if(value.id == item.item.id){
                    this.courseItems.splice(key, 1)
                }
            })
        }
    },

    created() {
        this.defineForm = {
            name: null,
            nameEn: null,
            facultyCode: null,
            departmentCode: null,
            programCode: null,
            type: null,
            status: 'a'
        }
    },
};
</script>
